module.exports = [
  {
    name: "Pembayaran Tagihan",
    slug: "pembayaran-tagihan",
    title: "Solusi Pembayaran Tagihan",
    description: `Solusi Pembayaran Tagihan kami memudahkan Anda dalam mengelola dan membayar berbagai tagihan dengan cepat dan aman. Dengan fitur yang mudah digunakan, Anda dapat melacak pembayaran dan menghindari keterlambatan.`,
    image: "/image/done.svg",
  },
  {
    name: "PPDB Online",
    slug: "ppdb-online",
    title: "Solusi PPDB Online",
    description: `Solusi PPDB Online kami menyediakan platform yang efisien untuk proses penerimaan peserta didik baru. Dengan sistem yang terintegrasi, pendaftaran menjadi lebih mudah dan transparan.`,
    image: "/image/done.svg",
  },
  {
    name: "Cek Tagihan",
    slug: "cek-tagihan",
    title: "Solusi Cek Tagihan",
    description: `Solusi Cek Tagihan memungkinkan Anda untuk memeriksa status tagihan dengan mudah dan cepat. Dapatkan informasi tagihan terbaru dan hindari keterlambatan pembayaran dengan fitur ini.`,
    image: "/image/done.svg",
  },
  {
    name: "Pembukuan Akuntansi",
    slug: "pembukuan-akuntansi",
    title: "Solusi Pembukuan Akuntansi",
    description: `Solusi Pembukuan Akuntansi kami dirancang untuk membantu Anda mengelola keuangan dengan lebih baik. Dengan fitur pembukuan yang lengkap, Anda dapat mencatat dan mengatur transaksi secara efisien.`,
    image: "/image/done.svg",
  },
  {
    name: "Tabungan",
    slug: "tabungan",
    title: "Solusi Tabungan",
    description: `Solusi Tabungan kami membantu Anda mengatur dan merencanakan keuangan dengan lebih baik. Dengan fitur yang mudah digunakan, Anda dapat menabung dan mencapai tujuan finansial Anda dengan lebih cepat.`,
    image: "/image/done.svg",
  },
  {
    name: "Donasi",
    slug: "donasi",
    title: "Solusi Donasi",
    description: `Solusi Donasi kami memudahkan Anda untuk memberikan sumbangan kepada berbagai organisasi dan yayasan. Dengan fitur ini, berdonasi menjadi lebih mudah dan transparan.`,
    image: "/image/done.svg",
  },
];
