import Cazh from "@/data/Payment/Cazh";
import Minimarket from "@/data/Payment/Minimarket";
import QR from "@/data/Payment/QR";
import VA from "@/data/Payment/VA";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FaChevronDown } from "react-icons/fa6";

export default function PaymentDropdown() {
  let path = usePathname();
  return (
    <div className="dropdown">
      <div
        tabIndex={0}
        role="button"
        className={`flex items-center gap-2 font-semibold ${path.startsWith('/pembayaran/') ? 'text-white bg-primary rounded-full py-2 px-8' : "text-dark-muted"}`}
      >
        Pembayaran <FaChevronDown className="w-3 h-3" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-[22rem] mt-6"
      >
        <div className="flex">
          <div>
            
          <li>
          <h2 className="menu-title">CAZH</h2>
          <ul>
            {Cazh.map((x, idx) => {
              return (
                <li key={idx}>
                  <Link href={`/pembayaran/cazh/${x.slug}`}>
                    <p className={path === `/pembayaran/cazh/${x.slug}` ? "border-b-2 w-32 border-primary" : ''}>{x.name}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <h2 className="menu-title">Scan QR</h2>
          <ul>
            {QR.map((x, idx) => {
              return (
                <li key={idx}>
                  <Link href={`/pembayaran/qr/${x.slug}`}>
                    <p className={path === `/pembayaran/qr/${x.slug}` ? "border-b-2 w-32 border-primary" : ''}>{x.name}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <h2 className="menu-title">Minimarket</h2>
          <ul>
            {Minimarket.map((x, idx) => {
              return (
                <li key={idx}>
                  <Link href={`/pembayaran/minimarket/${x.slug}`}>
                    <p className={path === `/pembayaran/minimarket/${x.slug}` ? "border-b-2 w-32 border-primary" : ''}>{x.name}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
          </div>
          <div>
          <li>
          <h2 className="menu-title">VA Bank</h2>
          <ul>
            {VA.map((x, idx) => {
              return (
                <li key={idx}>
                  <Link href={`/pembayaran/va-bank/${x.slug}`}>
                    <p className={path === `/pembayaran/va-bank/${x.slug}` ? "border-b-2 w-32 border-primary" : ''}>{x.name}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
          </div>
        </div>
        
        
      </ul>
    </div>
  );
}
