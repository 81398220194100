module.exports = [
  {
    name: "Progres Kegiatan",
    slug: "progres-kegiatan",
    title: "Solusi Progres Kegiatan",
    description: `Memungkinkan kesiswaan untuk melacak dan memantau perkembangan akademik siswa secara terperinci. Dengan solusi ini, para orang tua dapat memantau progres siswa dalam berbagai aspek akademik.`,
    image: "/image/done.svg",
  },
  {
    name: "Prestasi",
    slug: "prestasi",
    title: "Solusi Prestasi",
    description: `Memungkinkan kesiswaan untuk mengelola dan mengakses prestasi siswa secara terperinci, sementara orang tua dapat memantau perkembangan prestasi anak mereka.`,
    image: "/image/done.svg",
  },
  {
    name: "Pelanggaran",
    slug: "pelanggaran",
    title: "Solusi Poin Pelanggaran",
    description: `Memungkinkan kesiswaan untuk mencatat dan melacak pelanggaran perilaku siswa secara efisien. Dengan solusi ini, para orangtua dapat memantau setiap pelanggaran yang dilakukan oleh siswa.`,
    image: "/image/done.svg",
  },
  {
    name: "Perizinan",
    slug: "perizinan",
    title: "Solusi Perizinan",
    description: `Solusi Perizinan kami memudahkan pengelolaan dan pencatatan izin siswa secara efektif. Dengan fitur ini, proses pengajuan dan persetujuan izin menjadi lebih cepat dan terorganisir.`,
    image: "/image/done.svg",
  },
  {
    name: "Kesehatan",
    slug: "kesehatan",
    title: "Solusi Kesehatan",
    description: `Solusi Kesehatan kami membantu dalam mencatat dan memantau kondisi kesehatan siswa. Dengan fitur ini, informasi kesehatan siswa dapat diakses dengan mudah, memastikan penanganan yang tepat dan cepat.`,
    image: "/image/done.svg",
  },
];
