module.exports = [
  {
    name: "CAZHPOIN",
    slug: "cazhpoin",
    title: "Bayar Tagihan Pakai CAZHPOIN",
    description: `CAZHPOIN adalah solusi pembayaran praktis dan efisien untuk membayar berbagai tagihan Anda. Dengan CAZHPOIN, Anda dapat melakukan transaksi secara cepat, aman, dan tanpa repot. Nikmati kemudahan bertransaksi hanya dengan beberapa klik.`,
    logo: null,
    image: "/image/payment.svg",
  },
  {
    name: "Kartu Anggota",
    slug: "kartu-anggota",
    title: "Bayar Tagihan Pakai Kartu Anggota",
    description: `Kartu Anggota memberikan Anda kemudahan dalam membayar tagihan dengan keuntungan tambahan sebagai anggota. Manfaatkan berbagai fitur eksklusif dan nikmati proses pembayaran yang lebih lancar dan menguntungkan.`,
    logo: null,
    image: "/image/payment.svg",
  },
];
