module.exports = [
  {
    name: "Presensi Harian",
    slug: "presensi-harian",
    title: "Solusi Presensi Harian",
    description: `Memungkinkan guru dan manajemen sekolah untuk mengatur presensi harian siswa, sementara orang tua dapat melihatnya dan siswa dapat membuat presensinya sendiri.`,
    image: "/image/done.svg",
  },
  {
    name: "Presensi Mata Pelajaran",
    slug: "presensi-mata-pelajaran",
    title: "Solusi Presensi Mata Pelajaran",
    description: `Memungkinkan guru dan manajemen sekolah untuk mengatur presensi harian siswa, sementara orang tua dapat melihatnya dan siswa dapat membuat presensinya sendiri.`,
    image: "/image/done.svg",
  },
  {
    name: "Presensi Kegiatan",
    slug: "presensi-kegiatan",
    title: "Solusi Presensi Kegiatan",
    description: `Presensi Kegiatan adalah solusi canggih untuk mengelola kehadiran dalam berbagai kegiatan Anda. Dengan fitur yang mudah digunakan, Anda dapat memonitor dan mencatat kehadiran secara real-time, memastikan akurasi dan efisiensi.`,
    image: "/image/done.svg",
  },
  {
    name: "Rapor Siswa",
    slug: "rapor-siswa",
    title: "Solusi Rapor Siswa",
    description: `Memungkinkan guru dan manajemen sekolah untuk mengelola pembuatan, pengelolaan, dan penyebaran rapor siswa, sementara orang tua dapat melihatnya untuk memantau perkembangan akademis anak-anak mereka.`,
    image: "/image/done.svg",
  },
  {
    name: "Jadwal Pelajaran",
    slug: "jadwal-pelajaran",
    title: "Solusi Jadwal Pelajaran",
    description: `Platform yang memfasilitasi pengaturan dan penyebaran jadwal pelajaran oleh guru dan manajemen sekolah, serta memungkinkan para orang tua atau wali murid untuk melihat jadwal tersebut dengan mudah.`,
    image: "/image/done.svg",
  },
];
