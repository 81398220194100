import Link from "next/link";
import { usePathname } from "next/navigation";
import { FaChevronDown } from "react-icons/fa6";

export default function PartnerDropdown() {
  let path = usePathname()
  return (
    <div className="dropdown">
      <div
        tabIndex={0}
        role="button"
        className={`flex items-center gap-2 font-semibold ${path.startsWith('/partner/') ? 'text-white bg-primary rounded-full py-2 px-8' : "text-dark-muted"}`}
      >
        Partner <FaChevronDown className="w-3 h-3" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 mt-6"
      >
        <li>
          <Link href="/partner/sekolah">
            <p className={path === '/partner/sekolah' ? "border-b-2 w-40 border-primary" : ''}>Sekolah</p>
          </Link>
        </li>
        <li>
          <Link href="/partner/pesantren">
            <p className={path === '/partner/pesantren' ? "border-b-2 w-40 border-primary" : ''}>Pesantren</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}
