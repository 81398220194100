import Link from "next/link";
import { usePathname } from "next/navigation";
import { FaChevronDown } from "react-icons/fa6";

export default function AboutDropdown() {
  let path = usePathname();
  return (
    <div className="dropdown">
      <div tabIndex={0} role="button" className={`flex items-center gap-2 font-semibold text-white/50 text-[16px]`}>
        Kami <FaChevronDown className="w-3 h-3" />
      </div>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 mt-6">
        <li>
          <Link href="/tentang/kami">
            <p className={path === "/tentang/kami" ? "border-b-2 w-40 border-primary" : ""}>Tim CARDS</p>
          </Link>
        </li>
        <li>
          <Link href="/tentang/mitra-bisnis">
            <p className={path === "/tentang/mitra-bisnis" ? "border-b-2 w-40 border-primary" : ""}>Mitra Bisnis</p>
          </Link>
        </li>
        <li>
          <Link href="/tentang/berita">
            <p className={path === "/tentang/berita" ? "border-b-2 w-40 border-primary" : ""}>Berita</p>
          </Link>
        </li>
        <li>
          <Link href="/tentang/kegiatan">
            <p className={path === "/tentang/kegiatan" ? "border-b-2 w-40 border-primary" : ""}>Kegiatan</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}
