module.exports = [
  {
    name: "Tagihan",
    slug: "tagihan",
    title: "Solusi Tagihan",
    description: `Solusi Tagihan kami memudahkan Anda dalam mengelola dan membayar berbagai tagihan. Dengan antarmuka yang intuitif dan fitur yang komprehensif, Anda dapat melacak dan menyelesaikan pembayaran dengan mudah dan cepat, memastikan tidak ada tagihan yang terlewat.`,
    image: "/image/done.svg",
  },
  {
    name: "Inventaris",
    slug: "inventaris",
    title: "Solusi Inventaris",
    description: `Solusi Inventaris kami dirancang untuk membantu Anda mengelola aset dan persediaan dengan efisien. Dengan fitur pelacakan yang canggih, Anda dapat memonitor status inventaris secara real-time, mengoptimalkan penggunaan sumber daya, dan memastikan ketersediaan barang yang diperlukan.`,
    image: "/image/done.svg",
  },
  {
    name: "Donasi",
    slug: "donasi",
    title: "Solusi Donasi",
    description: `Bertujuan untuk memfasilitasi proses pengumpulan dan pengelolaan donasi secara efisien dan transparan. Dengan solusi ini, organisasi atau sekolah dapat dengan mudah membuat kampanye donasi untuk berbagai tujuan.`,
    image: "/image/done.svg",
  },
];
