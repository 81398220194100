"use client";

import { IoIosMenu } from "react-icons/io";
import SolutionDropdown from "./NavbarDropdown/Solution";
import PartnerDropdown from "./NavbarDropdown/Partner";
import PaymentDropdown from "./NavbarDropdown/Payment";
import AboutDropdown from "./NavbarDropdown/About";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { RiCustomerService2Fill } from "react-icons/ri";

export default function Navbar() {
  let path = usePathname();
  return (
    <div className="fixed z-40 w-full px-4 md:px-32 py-2 3xl:py-4 bg-[#00718F]">
      <div className="py-2 rounded-full flex justify-between items-center px-8">
        <Link href="/">
          <img src="/logo-white.png" alt="Cards logo" className="w-32" />
        </Link>
        <div className="lg:flex items-center gap-[100px] hidden text-sm">
          {/* <Link href="/" className={`text-white/50 text-[16px] font-semibold flex items-center gap-2`}>
            Beranda
          </Link>
          <Link href="/feature" className={`text-white/50 text-[16px] font-semibold flex items-center gap-2`}>
            Fitur
          </Link> */}
          {/* <PartnerDropdown />
          <SolutionDropdown />
          <PaymentDropdown /> */}
          {/* <AboutDropdown /> */}
          <div className="hidden lg:block text-sm">
            <Link href="/kontak" className="btn btn-accent text-white font-semibold rounded-full px-8 !min-h-0 h-full py-3 w-[192px] text-[18px]">
              Kontak
              {/* <RiCustomerService2Fill className="w-5 h-5" /> */}
            </Link>
          </div>
        </div>
        <div className="block lg:hidden">
          <label htmlFor="my-drawer" className="cursor-pointer drawer-button">
            <IoIosMenu className="w-8 h-8 fill-dark-muted" />
          </label>
        </div>
      </div>
    </div>
  );
}
